<template>
  <div style="background-color: #fafafa">
    <v-container>
      <div class="coverage">
        <h1>Cobertura</h1>

        <div class="coverage-container">
          <v-layout row wrap>
            <v-card max-width="325">
              <v-img :src="Coverage1" height="250px"></v-img>
              <v-card-title>
                Consulta médica veterinaria a domicilio o en red por emergencia
              </v-card-title>
              <v-card-text>
                Nuestros mejores amigos pueden tener una urgencia en cualquier
                momento, por eso nosotros estamos dispuestos a atenderlos ante
                cualquier accidente las 24 horas del día.
              </v-card-text>
              <hr class="mx-4" color="#0075bc" size="1" />
              <v-card-text>
                <span style="font-weight: bold">Límite de atención:</span> Tope
                UF 3, 1 evento al año.
              </v-card-text>
            </v-card>

            <v-card max-width="325">
              <v-img :src="Coverage2" height="250px"></v-img>
              <v-card-title> Vacunación </v-card-title>
              <v-card-text>
                Cubrimos las vacunas tradicionales de tu mejor amigo:
                antirrábica y séxtuple, y en el centro que quieras.
              </v-card-text>
              <hr class="mx-4" color="#0075bc" size="1" />
              <v-card-text>
                <span style="font-weight: bold">Límite de atención:</span> Tope
                UF 3, 2 eventos.
              </v-card-text>
            </v-card>

            <v-card max-width="325">
              <v-img :src="Coverage3" height="250px"></v-img>
              <v-card-title>
                Cremación por enfermedad o accidente
              </v-card-title>
              <v-card-text>
                Sabemos lo difícil que puede ser ese momento, y queremos estar
                ahí contigo apoyándote.
              </v-card-text>
              <hr class="mx-4" color="#0075bc" size="1" />
              <v-card-text>
                <span style="font-weight: bold">Límite de atención:</span> Tope
                UF 3, 1 evento.
              </v-card-text>
            </v-card>

            <v-card max-width="325">
              <v-img :src="Coverage4" height="250px"></v-img>
              <v-card-title>
                Responsabilidad civil extracontractual
              </v-card-title>
              <v-card-text>
                Porque ellos también necesitan que los cuiden como se merecen,
                por eso siempre estamos contigo para ayudarte en los cuidados
                que necesita tu mascota.
              </v-card-text>
              <hr class="mx-4" color="#0075bc" size="1" />
              <v-card-text>
                <span style="font-weight: bold">Límite de atención:</span>
                Acompañamiento jurídico, un evento máximo por asegurado.
              </v-card-text>
            </v-card>

            <v-card max-width="325">
              <v-img :src="Coverage5" height="250px"></v-img>
              <v-card-title> Servicio Aló Doctor </v-card-title>
              <v-card-text>
                Orientación médica veterinaria a través de nuestro número
                telefónico, con atención 24 horas. Tu mascota estará amparada al
                contar con la atención directa de un veterinario.
              </v-card-text>
              <v-card-text>
                – Solución a: Molestias, síntomas y enfermedades de tu mascota.
              </v-card-text>
              <hr class="mx-4" color="#0075bc" size="1" />
              <v-card-text>
                <span style="font-weight: bold">Límite de atención:</span>
                Atención ilimitada para el asegurador.
              </v-card-text>
            </v-card>

            <v-card max-width="325">
              <v-img :src="Coverage6" height="250px"></v-img>
              <v-card-title> Hotelería para mascotas </v-card-title>
              <v-card-text>
                ¿Tienes un imprevisto y no tienes quién cuide a tu mascota? Aquí
                tienes la solución, para que se quede bien cuidado y en un lugar
                que te da confianza.
              </v-card-text>
              <hr class="mx-4" color="#0075bc" size="1" />
              <v-card-text>
                <span style="font-weight: bold">Límite de atención:</span> Tope
                5 días / Máx. 0.5 UF por día, 1 evento al año.
              </v-card-text>
            </v-card>
          </v-layout>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Coverage1 from "@/assets/images/Coverage1.png";
import Coverage2 from "@/assets/images/Coverage2.png";
import Coverage3 from "@/assets/images/Coverage3.png";
import Coverage4 from "@/assets/images/Coverage4.png";
import Coverage5 from "@/assets/images/Coverage5.png";
import Coverage6 from "@/assets/images/Coverage6.png";

export default {
  name: "Coverage",
  data: () => ({
    Coverage1,
    Coverage2,
    Coverage3,
    Coverage4,
    Coverage5,
    Coverage6,
  }),
};
</script>

<style lang="scss">
.coverage {
  padding: 4rem 0;

  h1 {
    text-align: center;
    font-size: 2.5rem;
  }

  .coverage-container {
    .layout.wrap {
      justify-content: space-around;
    }

    .v-card {
      margin: 1rem;
    }

    .v-card__title {
      font-weight: bold;
      word-break: unset;
      line-height: 1.2;
    }

    .v-card__text {
      color: black !important;
      line-height: 1.2;
    }
  }
}
</style>